<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <b-form>
        <div>
          <h1 class="mr-sm-4 header-tablepage">Product Mobile Detail</h1>
        </div>
        <div class="title-tabs mt-3">Product Info</div>
        <b-container class="no-gutters bg-white">
          <div class="py-3">
            <b-row>
              <b-col md="6">
                <InputText
                  textFloat="Product Name"
                  placeholder="Product Name"
                  type="text"
                  name="productname"
                  isRequired
                  v-model="form.product.name"
                  :v="$v.form.product.name"
                />
              </b-col>
              <b-col md="6">
                <InputText
                  textFloat="Price"
                  placeholder="Price"
                  type="number"
                  name="price"
                  isRequired
                  v-model="form.product.price"
                  :v="$v.form.product.price"
                />
              </b-col>
              <b-col md="6">
                <ModalCategory
                  v-model="form.product.category"
                  :isValidate="$v.form.product.category.$error"
                  :v="$v.form.product.category"
                  textFloat="Category"
                  name="category"
                  :CategoryList="CategoryList"
                  :isBusy="isBusy"
                  :rows="rowCategory"
                  :showingTo="showingTo"
                  :showing="showing"
                  :changeList="changeList"
                  :categoryidList="categoryidList"
                  v-on:clearListcategory="clearListcategory"
                  v-on:setCategoryList="setCategoryList"
                  v-on:pagination="pagination"
                  v-on:setShowonly="setShowonly"
                  v-on:setUpSearch="setUpSearch"
                  isRequired
                />
              </b-col>
              <b-col md="6">
                <ModalCategory
                  v-model="form.product.relate_mobile_category_id"
                  :isValidate="$v.form.product.relate_mobile_category_id.$error"
                  :v="$v.form.product.relate_mobile_category_id"
                  textFloat="Relate Category"
                  name="relatecategory"
                  :CategoryList="RelatecategoryList"
                  :isBusy="isBusy"
                  :rows="rowRetatecategory"
                  :showingTo="showingToRetate"
                  :showing="showingRetate"
                  :nameselect="namerelatecategoryselect"
                  :relatecategoryidList="relatecategoryidList"
                  v-on:clearNamecategory="clearNamecategory"
                  v-on:setCategoryList="setCategoryList"
                  v-on:pagination="pagination"
                  v-on:setShowonly="setShowonly"
                  v-on:setUpSearch="setUpSearch"
                  isRequired
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <UploadFile
                  textFloat="Cover Image"
                  placeholder="Please Choose File"
                  format="all"
                  :fileName="form.product.image_url"
                  v-model="form.product.image_url"
                  name="thumbnail"
                  text="*Please upload only .png, .jpg image with ratio 1:1"
                  isRequired
                  :accept="acceptType"
                  :v="$v.form.product.image_url"
                  v-on:onFileChange="onImageChange"
                  :disabled="isDisable"
                />
                <PreviewBox
                  v-on:delete="deleteImage"
                  :disabled="isDisable"
                  :showPreviewType="0"
                  :showPreview="showPreview"
                  :isLoadingImage="isLoadingImage"
                  :ratioType="1"
                />
              </b-col>
              <b-col md="6">
                <InputText
                  class="mb-4"
                  textFloat="Skin Type"
                  placeholder="Skin Type"
                  type="text"
                  name="skintype"
                  isRequired
                  :v="$v.form.product.skin_suit"
                  v-model="form.product.skin_suit"
                />
              </b-col>
              <b-col md="12" class="mt-3 mb-3">
                <PreviewMultipleImages
                  v-on:deleteImages="deleteImagesList"
                  v-on:updateImageList="updateImageList"
                  :items="form"
                  :v="$v.form.product"
                />
              </b-col>
              <b-col md="12">
                <TextEditorsTiny
                  textFloat="Description"
                  :rows="4"
                  :value="form.product.description"
                  :name="'description_' + 1"
                  placeholder="Type something..."
                  :v="$v.form.product.description"
                  @onDataChange="(val) => (form.product.description = val)"
                  isRequired
                />
              </b-col>
            </b-row>
            <div class="product-list-table">
              <div class="title-tabs">Purchase Chanel</div>
              <b-container class="no-gutters bg-white">
                <div class="pt-3">
                  <PurchaseChanel
                    @getSellChannel="getSellChannel"
                    :PurchaseChanelList="SellChanelList"
                    :rows="rows"
                    :idList="sellChanelidList"
                    v-on:addsellChanel="addsellChanel"
                    v-on:splicesellChanel="splicesellChanel"
                  />
                </div>
              </b-container>
            </div>
          </div>
        </b-container>
        <div class="no-gutters bg-white mt-3 py-2 px-3">
          <b-form-checkbox
            switch
            v-model="form.product.status"
            class="radio-active"
            size="lg"
            :value="1"
            :unchecked-value="0"
          >
            <span class="ml-2 main-label">{{
              form.product.status ? "Active" : "Inactive"
            }}</span>
          </b-form-checkbox>
        </div>
        <FooterAction @submit="checkForm(0)" routePath="/productmobile" />
      </b-form>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import UploadFile from "@/components/inputs/UploadFile";
import OtherLoading from "@/components/loading/OtherLoading";
import TextEditorsTiny from "@/components/inputs/TextEditorsTiny";
import ModalCategory from "@/components/modal/ModalCategory";
import PurchaseChanel from "@/components/purchaseChanel/Table";
import { required } from "vuelidate/lib/validators";
import PreviewBox from "@/components/PreviewBox.vue";
import PreviewMultipleImages from "@/components/PreviewMultipleImages.vue";
export default {
  name: "ProductDetails",
  components: {
    InputText,
    ModalCategory,
    PurchaseChanel,
    UploadFile,
    TextEditorsTiny,
    OtherLoading,
    PreviewBox,
    PreviewMultipleImages,
  },
  data() {
    return {
      id: this.$route.params.id,
      isLoading: false,
      isDisable: false,
      isEdit: false,
      isLoadingImage: false,
      showVideo: "",
      showPreview: "",
      acceptType: "image/jpeg, image/png",
      SellChanelList: [],
      isSuccess: false,
      sellChannelList: [],
      CategoryList: [],
      rowCategory: 0,
      categoryidList: [],
      changeList: false,
      relatecategoryidList: 0,
      newrelatecategoryidList: "",
      RelatecategoryList: [],
      rowRetatecategory: 0,
      namerelatecategoryselect: "",
      sellChanelidList: [],
      selectCategory: [],
      newselectCategory: [],
      isBusy: false,
      showOnlySelecteCategory: false,
      showOnlySelecteRelatecategory: false,
      image: {
        size: "",
        height: "",
        width: "",
      },
      showing: 1,
      showingTo: 5,
      showingRetate: 1,
      showingToRetate: 5,
      rows: 0,
      filter: {
        search: "",
        page: 1,
        take: 5,
      },
      form: {
        product: {
          id: 0,
          name: "",
          description: "",
          image_url: "",
          skin_suit: "",
          status: 0,
          price: "",
          relate_mobile_category_id: "",
          relate_mobile_category_text: "",
          image_list: [],
          sell_channel: [],
          category: [],
          is_serial: 0,
        },
        productId: [],
      },
    };
  },
  validations: {
    form: {
      product: {
        name: { required },
        price: { required },
        description: { required },
        image_url: { required },
        skin_suit: { required },
        category: { required },
        relate_mobile_category_id: { required },
      },
    },
  },
  created: async function () {
    if (this.id !== "0") {
      await this.getDatas();
    } else {
      this.getSellChannel();
    }
  },
  methods: {
    getDatas: async function () {
      await this.$store.dispatch("ActionGetProductByID", this.id);
      var data = await this.$store.state.moduleConnectAPI.stateGetProductByID;
      if (data.result == 1) {
        this.form.product = data.detail;
        this.showPreview = this.form.product.image_url;
        for (let i = 0; i < data.detail.sell_channel.length; i++) {
          this.sellChanelidList.push(
            data.detail.sell_channel[i].sell_channel_id
          );
        }
        for (let k = 0; k < data.detail.category.length; k++) {
          this.categoryidList.push(data.detail.category[k].id);
        }
        this.form.product.image_list_delete = [];
        this.form.product.category_list_delete = [];
        this.form.product.sell_channel_list_delete = [];
        this.selectCategory = this.categoryidList;
        this.relatecategoryidList = data.detail.relate_mobile_category_id;
        this.namerelatecategoryselect = data.detail.relate_category_name;
        this.updateImageList(data.detail.image_list);
        this.getSellChannel();
      }
    },
    async getSellChannel() {
      var data = "";
      if (this.id === "0") {
        await this.$store.dispatch("ActionSellChannel");
        data = await this.$store.state.moduleConnectAPI.stateSellChannel;
        if (data.result == 1) {
          for (let i = 0; i < data.detail.length; i++) {
            data.detail[i].active = true;
          }
          this.SellChanelList = data.detail;
        }
      } else {
        await this.$store.dispatch("ActionSellChannel");
        data = await this.$store.state.moduleConnectAPI.stateSellChannel;
        if (data.result == 1) {
          data.detail = data.detail.map((op) => {
            return { ...op, active: true, url: "" };
          });
          for (let i = 0; i < data.detail.length; i++) {
            const even = (element) =>
              element.sell_channel_id === data.detail[i].id;
            var ischeck = this.form.product.sell_channel.some(even);
            const condition = (element) =>
              element.sell_channel_id === data.detail[i].id;
            var idIndex = this.form.product.sell_channel.findIndex(condition);
            if (ischeck) {
              data.detail[i].active = false;
              if (idIndex !== -1) {
                data.detail[i].url =
                  this.form.product.sell_channel[idIndex].url;
              }
            } else {
              data.detail[i].active = true;
            }
          }
          this.SellChanelList = data.detail;
        }
      }
      this.GetSubCategory();
      this.GetRelateCategory();
    },
    async GetSubCategory() {
      this.isBusy = true;
      var setData = {
        productId: this.id,
        page: this.filter.page,
        take: this.filter.take,
        show_only_selected: this.showOnlySelecteCategory,
        select_category: this.newselectCategory,
        search: this.filter.search,
        delete_category: this.form.product.category_list_delete,
      };
      await this.$store.dispatch("ActionGetSubCategory", setData);
      var data = await this.$store.state.moduleConnectAPI.stateGetSubCategory;
      if (data.result == 1) {
        this.CategoryList = data.detail.detail;
        this.rowCategory = data.detail.total_count;
        this.isBusy = false;
      }
    },
    async GetRelateCategory() {
      this.isBusy = true;
      var setData = {
        productId: this.id,
        page: this.filter.page,
        take: this.filter.take,
        show_only_selected: this.showOnlySelecteRelatecategory,
        select_category: this.newrelatecategoryidList,
        search: this.filter.search,
      };
      await this.$store.dispatch("ActionGetRelateCategory", setData);
      var data = await this.$store.state.moduleConnectAPI
        .stateGetRelateCategory;
      if (data.result == 1) {
        this.RelatecategoryList = data.detail.detail;
        this.rowRetatecategory = data.detail.total_count;
        this.isBusy = false;
      }
    },
    setShowonly(show, name, listID, ID) {
      if (name === "category") {
        this.categoryidList = listID;
        this.showOnlySelecteCategory = show;
        this.pagination(1, name);
      } else {
        this.newrelatecategoryidList = [ID];
        this.showOnlySelecteRelatecategory = show;
        this.pagination(1, name);
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    setUpSearch(value, name) {
      if (name === "category") {
        this.filter.search = value.search;
        this.filter.page = value.page;
        this.filter.take = value.take;
        this.GetSubCategory();
      } else {
        this.filter.search = value.search;
        this.filter.page = value.page;
        this.filter.take = value.take;
        this.GetRelateCategory();
      }
    },
    pagination(page, name) {
      this.filter.page = page;
      if (name === "category") {
        if (page === 1) {
          this.showing = this.filter.page;
          this.showingTo = this.filter.take;
        } else {
          this.showing = page * this.filter.take - this.filter.take;
          this.showingTo = this.showing + this.filter.take;
        }
        this.GetSubCategory();
      } else {
        if (page === 1) {
          this.showingRetate = this.filter.page;
          this.showingToRetate = this.filter.take;
        } else {
          this.showingRetate = page * this.filter.take - this.filter.take;
          this.showingToRetate = this.showingRetate + this.filter.take;
        }
        this.GetRelateCategory();
      }
    },
    clearListcategory(list) {
      if (this.id !== "0") {
        this.changeList = true;
        this.form.product.category = [];
        this.categoryidList = [];
        this.form.product.category_list_delete = list;
        this.newselectCategory = [];
      } else {
        this.newselectCategory = [];
      }
    },
    clearNamecategory() {
      this.namerelatecategoryselect = "";
      this.form.product.relate_category_name = "";
      this.form.product.relate_mobile_category_id = "";
    },
    addsellChanel(list) {
      const even = (element) => element.sell_channel_id === list.id;
      var ischeck = this.form.product.sell_channel.some(even);
      if (ischeck === false) {
        this.form.product.sell_channel.push({
          sell_channel_id: list.id,
          url: list.url,
        });
      } else {
        var data = {
          sell_channel_id: list.id,
          url: list.url,
        };
        const condition = (element) => element.sell_channel_id === list.id;
        var idIndex = this.form.product.sell_channel.findIndex(condition);
        this.form.product.sell_channel[idIndex] = data;
      }
    },
    splicesellChanel(id) {
      const even = (element) => element.sell_channel_id === id;
      var ischeck = this.form.product.sell_channel.some(even);
      const condition = (element) => element.sell_channel_id === id;
      var idIndex = this.form.product.sell_channel.findIndex(condition);
      if (ischeck) {
        this.form.product.sell_channel_list_delete.push(
          this.form.product.sell_channel[idIndex].id
        );
        this.form.product.sell_channel.splice(idIndex, 1);
      }
    },
    updateImageList(list) {
      this.form.product.image_list = list;
    },
    deleteImagesList(id) {
      this.form.product.image_list_delete.push(id);
    },
    onImageChange(val) {
      this.isLoadingImage = true;
      this.isDisable = true;

      var reader = new FileReader();
      reader.readAsDataURL(val);
      reader.onload = async () => {
        let img = new Image();
        img.onload = () => {
          this.image.width = img.width;
          this.image.height = img.height;
          if (parseInt(this.image.height) !== parseInt(this.image.width)) {
            this.$swal("Please upload image with ratio 1:1 !", {
              icon: "info",
            });
            this.deleteImage();
            this.isLoadingImage = false;
            this.isDisable = false;
          } else {
            this.setDataImage(reader.result);
          }
        };
        img.src = await reader.result;
      };
    },
    async setDataImage(base64) {
      var url = await this.saveImagetoDb(base64, false);
      this.isLoadingImage = false;
      this.isDisable = false;
      this.showPreview = url;
      this.form.product.image_url = url;
    },
    saveImagetoDb: async function (img, isvideo) {
      var form = {
        Base64String: img,
        is_video: isvideo,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      } else {
        alert("Unsuccess!");
      }
    },
    deleteImage() {
      this.form.product.image_url = null;
      this.showPreview = null;
    },
    setCategoryList(idlist, name, id, change) {
      if (name == "category") {
        if (this.id !== "0") {
          this.changeList = change;
          this.form.product.category = idlist;
          const even1 = (element) => element === id;
          var ischeck1 = this.form.product.category.some(even1);
          const even2 = (element) => element === id;
          var ischeck2 = this.form.product.category_list_delete.some(even2);
          if (ischeck1 === false && ischeck2 === false) {
            this.form.product.category_list_delete.push(id);
            this.checkselectCategory(id);
          } else if (ischeck1 === true && ischeck2 === true) {
            const condition = (element) => element === id;
            var idIndex =
              this.form.product.category_list_delete.findIndex(condition);
            this.form.product.category_list_delete.splice(idIndex, 1);
          } else if (ischeck1 === true && ischeck2 === false) {
            this.checkselectCategory(id);
          }
        } else {
          this.form.product.category = idlist;
          this.newselectCategory = idlist;
          this.form.product.category_list_delete = [];
        }
      } else {
        this.form.product.relate_mobile_category_id = idlist;
      }
    },
    checkselectCategory(id) {
      const even1 = (element) => element === id;
      var ischeck1 = this.selectCategory.some(even1);
      const even2 = (element) => element === id;
      var ischeck2 = this.newselectCategory.some(even2);
      if (ischeck1 === false && ischeck2 === false) {
        this.newselectCategory.push(id);
      } else if (ischeck1 === false && ischeck2 === true) {
        const condition = (element) => element === id;
        var idIndex = this.newselectCategory.findIndex(condition);
        this.newselectCategory.splice(idIndex, 1);
      }
    },
    checkForm: async function () {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.submit();
    },
    submit: async function () {
      this.$bus.$emit("showLoading");
      this.isDisable = true;
      var data = null;
      this.form.product.price = parseInt(this.form.product.price);

      if (this.id !== "0") {
        if (this.changeList === false) {
          this.form.product.category = this.categoryidList;
        }
        await this.$store.dispatch("ActionUpdatetProduct", this.form.product);
        data = await this.$store.state.moduleConnectAPI.stateUpdatetProduct;
        if (data.result == 1) {
          this.successAlert().then(() => this.$router.push("/productmobile"));
        }
      } else {
        await this.$store.dispatch("ActionCreateProduct", this.form.product);
        data = await this.$store.state.moduleConnectAPI.stateCreateProduct;
        if (data.result == 1) {
          this.successAlert().then(() => this.$router.push("/productmobile"));
        }
      }
      this.$bus.$emit("hideLoading");
    },
  },
};
</script>

<style scoped>
.product-list-table {
  border: 1px solid #d8dbe0;
}
</style>
