<template>
  <div>
    <b-button v-b-toggle.collapse-images class="collapse-btn content-between">
      <span class="img-note label">
        Images Support Only .jpg, .png for image (Maximum 10 MB)</span
      >
      <font-awesome-icon
        icon="chevron-down"
        class="icon float-right mt-1 when-opened"
      />
      <font-awesome-icon
        icon="chevron-left"
        class="icon float-right mt-1 when-closed"
      />
    </b-button>
    <b-collapse id="collapse-images" visible>
      <div class="thumbnailGroup p-3">
        <MultipleImages
          @updateImageList="updateImages"
          :dataList="form.product.image_list"
          :v="v.image_list"
          v-on:deleteImagesList="deleteImages"
        />
        <div v-if="form.product.productTypeId == 2">
          <label class="main-label mb-3">
            {{ form.product.children.label }}
          </label>
          <template v-if="forceRefresh">
            <draggable
              :list="form.product.productAttributeImage"
              handle=".handle"
              class="img-list m-0"
            >
              <div
                class="handle"
                v-for="(img, index) in form.product.productAttributeImage"
                :key="index"
              >
                <ImageUpload
                  v-bind:dataFile="img"
                  :index="index"
                  :name="img.altTag"
                  :v="v.productAttributeImage.$each.$iter[index].image_url"
                  @handleChangeImage="handleChangeImage"
                />
              </div>
            </draggable>
          </template>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import MultipleImages from "@/components/inputs/MultipleImages";
export default {
  components: {
    MultipleImages,
  },
  props: {
    items: {
      required: true,
      type: Object,
    },
    v: {
      required: true,
      type: Object,
    },
  },
  computed: {
    form() {
      return this.items;
    },
  },
  methods: {
    deleteImages() {
      this.$emit("deleteImagesList");
    },
    updateImages() {
      this.$emit("updateImageList");
    },
  },
};
</script>

<style scoped>
.thumbnailGroup {
  padding-bottom: 0px !important;
  border-top: 0px;
  border-left: 1px solid #bfbfbf;
  border-right: 1px solid #bfbfbf;
  border-bottom: 1px solid #bfbfbf;
}
.img-note {
  font-size: 12px;
  margin-left: 5px;
  width: 300px;
  display: contents;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  top: 4px;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

</style>
