<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="PurchaseChanelList"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:cell(id)="data">
            <b-form-checkbox
              size="lg"
              class="ml-3"
              :value="data.item.id"
              v-model="PurchaseChanelSelectedList"
              @change="checkActive(data.item)"
            ></b-form-checkbox>
          </template>
          <template v-slot:cell(image_url)="data">
            <div class="d-flex justify-content-center" v-if="data.item.image_url">
              <b-img class="box-image-component1-1" :src="data.item.image_url" fluid></b-img>
            </div>
          </template>
          <template v-slot:cell(url)="data">
            <b-form-input
              v-if="refreshInput"
              v-model="data.item.url"
              :disabled="data.item.active"
              @change="addsellChanel(data.item)"
              placeholder="Plaease insert external link"
            ></b-form-input>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  props: {
    PurchaseChanelList: {
      required: true,
      type: Object | Array,
    },
    rows: {
      required: false,
      type: Number,
    },
    idList: {
      required: false,
      type: Array,
    },
    isBusy: {
      required: false,
      type: Boolean,
    },
    section: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      isPurchaseChanel: true,
      PurchaseChanelSelectedList: this.idList,
      fields: [
        {
          key: "id",
          label: "#",
          thStyle: { width: "5%" },
        },
        {
          key: "image_url",
          label: "Image",
          thStyle: { width: "10%" },
          tdClass: "p-1"
        },
        {
          key: "name",
          label: "Name",
        },
        {
          key: "url",
          label: "Link",
          thStyle: { width: "30%" },
        },
      ],
      sellChanelList: [],
      refreshInput: true,
    };
  },
  created() {},
  methods: {
    onCheckDisable(index) {
      return this.PurchaseChanelList[index].active;
    },
    checkItemActive(id) {
      let target = this.PurchaseChanelList.find((el) => el.id == id);
      return target.active;
    },
    checkActive(item) {
      item.active = !item.active;
      this.refreshInput = false;
      this.$nextTick(() => {
        this.refreshInput = true;
      });
      if (item.active == true) {
        item.url = "";
        Vue.nextTick(() => {
          this.$emit("splicesellChanel", item.id);
        });
      }
    },
    addsellChanel(list) {
      Vue.nextTick(() => {
        this.$emit("addsellChanel", list);
      });
    },
  },
};
</script>
<style scoped>
.image {
  width: 50%;
  padding-top: 42.9%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
